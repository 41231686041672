/* eslint-disable react/no-unused-prop-types */
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Chip, Divider, Stack, Typography } from '@mui/material';
import { InfiniteData } from '@tanstack/react-query';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavigationPaths } from '../../constants';
import { RecipeBaseModel } from '../../modules/types';
import { defaultImageUrl } from '../../shared';
import { PaginatedResult } from '../../types';
import { ModalPopup } from '../modal-popup';

type Props = {
  recipes: InfiniteData<PaginatedResult<RecipeBaseModel>>;
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  ref?: React.Ref<HTMLDivElement>;
};

const AssignedRecipes: React.FC<Props> = React.forwardRef<HTMLDivElement, Props>(
  ({ recipes, isLoading, isOpen, onClose }, ref) => {
    const navigate = useNavigate();

    const handleNavigate = useCallback(
      (id: string) => {
        navigate(`${NavigationPaths.DETAILS}?recipeId=${id}`);
      },
      [navigate],
    );

    return (
      <ModalPopup isOpen={isOpen} onClose={onClose}>
        <Box
          sx={{
            width: '900px',
            borderRadius: '8px',
            bgcolor: 'kqn.white',
            p: 3,
            boxShadow: 24,
            overflow: 'hidden',
          }}
        >
          <Stack
            sx={{ color: 'kqn.copper' }}
            pb={2}
            direction='row'
            alignItems='center'
            justifyContent='space-between'
          >
            <Typography variant='h6'>ASSOCIATED RECIPES</Typography>
            <CancelIcon onClick={onClose} sx={{ cursor: 'pointer', color: 'kqn.copper' }} />
          </Stack>
          <Divider
            sx={{
              borderColor: 'kqn.copper',
            }}
          />
          <Stack gap={2} direction='column' pt={2} sx={{ maxHeight: '60vh', overflow: 'auto' }}>
            <Stack justifyContent='space-between' alignItems='center' direction='row'>
              <Typography align='center' width='100%'>
                Recipe
              </Typography>
              <Typography align='center' width='100%'>
                Category
              </Typography>
            </Stack>
            {isLoading && <Typography>Loading...</Typography>}
            {recipes?.pages.map((pg) => {
              return pg?.data.map((recipe, idx) => {
                return (
                  <Box ref={idx === pg.data.length - 1 ? ref : null} key={recipe.id}>
                    <Stack gap={2} justifyContent='space-between' direction='row'>
                      <Stack
                        direction='row'
                        width='100%'
                        gap={2}
                        sx={{
                          bgcolor: 'kqn.ultraLightGray',
                          p: 1,
                          pr: 2,
                          boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.4)',
                          borderRadius: '8px',
                          '&:hover': { cursor: 'pointer' },
                        }}
                        onClick={() => handleNavigate(recipe.id)}
                      >
                        <img
                          src={recipe.thumbnailUrl || recipe.imageUrl || defaultImageUrl}
                          alt={recipe.name}
                          style={{
                            width: '80px',
                            height: '80px',
                            borderRadius: '8px',
                            objectFit: 'cover',
                          }}
                        />
                        <Stack direction='column' justifyContent='space-between' width='100%'>
                          <Typography variant='body2'>{recipe.name}</Typography>
                          <Stack
                            direction='row'
                            alignItems='center'
                            justifyContent='space-between'
                            gap={2}
                            sx={{ color: 'kqn.darkGray' }}
                          >
                            <Stack direction='row' alignItems='center' gap={0.5}>
                              <AccessTimeIcon />
                              <Typography variant='body2'>{recipe.cookingTime}min</Typography>
                            </Stack>
                            <Typography variant='body2' align='right'>
                              {recipe.complexity.name}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                      <Stack direction='column' justifyContent='center' width='100%'>
                        <Stack
                          direction='row'
                          alignItems='center'
                          justifyContent='center'
                          flexWrap='wrap'
                          gap={2}
                        >
                          {recipe.categories?.map((category) => {
                            return (
                              <Chip
                                key={category.id}
                                label={category.name}
                                sx={{
                                  color: 'kqn.darkerGray',
                                  borderRadius: '8px',
                                  bgcolor: 'kqn.lightGray',
                                }}
                              />
                            );
                          })}
                        </Stack>
                      </Stack>
                    </Stack>
                    <Divider sx={{ width: '50%', mx: 'auto', mt: 2 }} />
                  </Box>
                );
              });
            })}
          </Stack>
        </Box>
      </ModalPopup>
    );
  },
);

export default AssignedRecipes;
