import { Box, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { Order } from '../../shared/helpers/get-comparator';
import { HeadCell } from '../../types';

interface EnhancedTableProps<T> {
  onRequestSort?: (event: React.MouseEvent<unknown>, property: keyof T) => void;
  order?: Order;
  orderBy?: string;
  headCells: readonly HeadCell<T>[];
  children?: React.ReactNode;
  hasCheckbox?: boolean;
}

export function EnhancedTableHead<T>({
  order,
  orderBy,
  onRequestSort,
  headCells,
  children,
  hasCheckbox = true,
}: EnhancedTableProps<T>) {
  const createSortHandler = (property: keyof T) => (event: React.MouseEvent<unknown>) => {
    onRequestSort?.(event, property);
  };

  return (
    <TableHead
      sx={{
        height: '55px',
        '& .MuiTableCell-root': {
          bgcolor: '#F4F4F4',
          color: '#201D1D',
        },
      }}
      data-testid='table-header'
    >
      {children}
      <TableRow>
        {hasCheckbox && <TableCell padding='checkbox' sx={{ height: '55px' }} />}
        {headCells.map((headCell) => {
          const isActive = orderBy === headCell.id;
          return (
            <TableCell
              colSpan={headCell.colSpan}
              sx={{ fontWeight: '600' }}
              key={String(headCell.id)}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={isActive}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                sx={{
                  textTransform: 'uppercase',
                  width: 'max-content',
                  color: `${isActive ? '#AF6B48 !important' : 'inherit'}`,
                  '> svg': { color: `${isActive ? '#AF6B48 !important' : 'inherit'}` },
                }}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component='span' sx={{ ...visuallyHidden, bgcolor: 'kqn.copper' }}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}
