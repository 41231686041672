import { Box, Divider, Stack, Typography } from '@mui/material';
import { ModalPopup } from '../modal-popup';
import { UpdateUserForm } from '../update-user-form';

interface Props {
  user?: any;
  isOpen: boolean;
  onClose: () => void;
}

const SidebarUserProfileModal: React.FC<Props> = ({ user, isOpen, onClose }) => {
  if (!user) return null;

  return (
    <ModalPopup isOpen={isOpen} onClose={onClose}>
      <Box
        border={0}
        sx={{
          minWidth: '600px',
          bgcolor: 'background.paper',
          p: 3,
          boxShadow: 24,
          borderRadius: '8px',
        }}
      >
        <Stack
          gap={2}
          sx={{ color: 'kqn.copper' }}
          pb={2}
          direction='row'
          alignItems='center'
          justifyContent='flex-start'
        >
          <Typography variant='h6'>USER PROFILE</Typography>
        </Stack>
        <Divider sx={{ borderColor: 'kqn.lightCopper' }} />
        <UpdateUserForm user={user} isProfile onClose={onClose} />
      </Box>
    </ModalPopup>
  );
};

export default SidebarUserProfileModal;
