import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Divider, FormControl, InputLabel, MenuItem, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useQuery } from '@tanstack/react-query';
import { Controller, useForm } from 'react-hook-form';
import {
  ComposerFooterButtons,
  KQNTextField,
  KQNTextInputField,
  ModalPopup,
} from '../../../../components';
import { queries } from '../../../queries';
import { useCreateUser } from '../../mutations';
import { createUserSchema, UserSchemaType } from './schema';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const CreateUserModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { handleCreateUser, isLoading: isSubmitting } = useCreateUser();
  const { control, handleSubmit } = useForm<UserSchemaType>({
    resolver: zodResolver(createUserSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      role: 'CONTENT MANAGER',
    },
  });

  const { data: roles, isLoading } = useQuery({
    ...queries.users.roles(),
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  const handleOnSubmit = async (data: UserSchemaType) => {
    await handleCreateUser({ data, onSuccess: onClose });
  };

  return (
    <ModalPopup isOpen={isOpen} onClose={onClose}>
      <Box border={0} sx={{ width: '600px', bgcolor: 'background.paper', p: 3, boxShadow: 24 }}>
        <Typography variant='h6' sx={{ color: 'kqn.copper', pb: 2 }}>
          ADD USER
        </Typography>
        <Divider sx={{ borderColor: 'kqn.copper' }} />
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <Stack pt={3} gap={2}>
            <Stack
              direction='row'
              justifyContent='space-between'
              gap={2}
              alignContent='center'
              alignItems='center'
            >
              <Stack width='100%' gap={1}>
                <InputLabel htmlFor='firstName' sx={{ color: 'kqn.darkerGray' }}>
                  First Name
                </InputLabel>
                <KQNTextInputField control={control} placeholder='First Name' name='firstName' />
              </Stack>
              <Stack width='100%' gap={1}>
                <InputLabel htmlFor='lastName' sx={{ color: 'kqn.darkerGray' }}>
                  Last Name
                </InputLabel>
                <KQNTextInputField
                  control={control}
                  name='lastName'
                  placeholder='Last Name'
                  data-testid='last-name'
                />
              </Stack>
            </Stack>
            <Stack
              direction='row'
              justifyContent='space-between'
              gap={2}
              alignContent='center'
              alignItems='center'
            >
              <Stack width='100%' gap={1}>
                <InputLabel htmlFor='email' sx={{ color: 'kqn.darkerGray' }}>
                  Email
                </InputLabel>
                <KQNTextInputField
                  type='email'
                  placeholder='test@koqoon.com'
                  data-testid='email'
                  control={control}
                  name='email'
                />
              </Stack>
              <Stack width='100%' gap={1}>
                <InputLabel htmlFor='password' sx={{ color: 'kqn.darkerGray' }}>
                  Password
                </InputLabel>
                <KQNTextInputField
                  control={control}
                  name='password'
                  type='password'
                  placeholder='********'
                  data-testid='password'
                />
              </Stack>
            </Stack>
            <Stack
              direction='row'
              justifyContent='space-between'
              gap={2}
              alignContent='center'
              alignItems='center'
            >
              <Stack width='100%' gap={1}>
                <InputLabel htmlFor='role' sx={{ color: 'kqn.darkerGray' }}>
                  Role
                </InputLabel>
                <Controller
                  disabled={isLoading}
                  name='role'
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <FormControl fullWidth>
                      <KQNTextField
                        data-testid='role-select'
                        size='small'
                        select
                        variant='outlined'
                        inputRef={ref}
                        {...field}
                      >
                        {roles?.map((role) => (
                          <MenuItem key={role.id} value={role.name}>
                            {role.name}
                          </MenuItem>
                        ))}
                      </KQNTextField>
                    </FormControl>
                  )}
                />
              </Stack>
              <Box width='100%' />
            </Stack>
            <ComposerFooterButtons onClose={onClose} isLoading={isSubmitting} />
          </Stack>
        </form>
      </Box>
    </ModalPopup>
  );
};

export default CreateUserModal;
