import { FormControlLabel, Stack, Switch, Typography } from '@mui/material';
import { useRecipeActions } from '../../../../store';
import { RecipeBaseModel } from '../../../types';
import { useRecipePublishing } from '../../mutations';

type Props = {
  recipe: RecipeBaseModel;
};

const RecipeInfo: React.FC<Props> = ({ recipe }) => {
  const { name } = recipe;
  const { updatePublished } = useRecipeActions();
  const { handlePublish } = useRecipePublishing();

  const handleSwitchChange = async () => {
    if (!recipe?.id) return;

    await handlePublish(recipe.id, updatePublished);
  };

  return (
    <Stack direction='row' alignItems='center' justifyContent='space-between'>
      <Stack direction='row' gap={4} alignItems='center'>
        <Typography variant='h5' sx={{ color: 'kqn.darkerGray' }}>
          {name || 'Add Recipe'}
        </Typography>
        {!!recipe.id && (
          <FormControlLabel
            control={
              <Switch
                size='medium'
                checked={recipe.isPublished}
                color='primary'
                inputProps={{ 'aria-label': 'primary checkbox' }}
                onChange={handleSwitchChange}
                sx={{
                  '& .MuiSwitch-switchBase.Mui-checked': { color: 'kqn.copper' },

                  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                    bgcolor: 'kqn.lightCopper',
                  },
                }}
              />
            }
            sx={{
              '.MuiFormControlLabel-label': { color: 'kqn.darkGray', fontSize: '12px' },
            }}
            label={recipe.isPublished ? 'PUBLISHED' : 'UNPUBLISHED'}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default RecipeInfo;
