import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import PersonIcon from '@mui/icons-material/Person';
import {
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { Paths } from '../../constants';
import { AuthUser, useAuthentication } from '../../context';
import { queries } from '../../modules/queries';
import { ChangePasswordModal } from '../modal';
import SidebarUserProfileModal from './sidebar-user-profile-modal';

export const drawerWidth = 240;

interface Props {
  user?: AuthUser;
  open: boolean;
}

const SidebarUserActions: React.FC<Props> = ({ user, open }) => {
  const { signOut } = useAuthentication();
  const [isChangePasswordOpen, setIsChangePasswordOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const { data, isFetching } = useQuery({
    ...queries.users.details(user?.id),
    enabled: !!user?.id,
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnWindowFocus: true,
  });

  const pageableUser: any = {
    ...data,
    role: data?.role?.name,
  };

  const handleOnProfileClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = async () => {
    try {
      signOut({ redirectPath: Paths.LOGIN });
      setAnchorEl(null);
    } catch (err: any) {
      toast.error(err);
    }
  };

  return (
    <Stack sx={{ position: 'absolute', bottom: 0 }}>
      <Toolbar
        disableGutters
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          px: '0px !important',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', px: 0 }}>
          <Stack
            onClick={handleOnProfileClick}
            direction='row'
            gap={2}
            sx={{
              px: '20px',
              py: 2,
              justifyContent: open ? 'initial' : 'center',
              alignItems: 'center',
              width: '100%',
              ':hover': { cursor: 'pointer' },
            }}
          >
            <IconButton
              data-testid='navbar-profile-btn'
              sx={{
                padding: '0px !important',
                color: 'kqn.darkerGray',
                fontSize: '1rem',
                px: 0,
                ':hover': { bgcolor: 'inherit' },
              }}
              size='large'
            >
              <PersonIcon />
            </IconButton>
            {open && (
              <Typography variant='body1' sx={{ color: 'kqn.darkerGray' }}>
                Profile
              </Typography>
            )}
          </Stack>
          <Menu
            open={isMenuOpen}
            id='action-menu'
            anchorEl={anchorEl}
            MenuListProps={{
              'aria-labelledby': 'action-btn',
              sx: { py: 1, px: 2 },
            }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            onClose={() => setAnchorEl(null)}
            sx={{
              '& .MuiMenu-paper': {
                minWidth: '200px',
                borderRadius: '8px',
              },
            }}
          >
            <MenuItem>
              {!isFetching ? (
                <Stack>
                  <Typography variant='body1' align='left' sx={{ fontWeight: 'bold' }}>
                    {data?.firstName} {data?.lastName}
                  </Typography>
                  <Typography variant='body2' align='left'>
                    {data?.email}
                  </Typography>
                </Stack>
              ) : (
                <Stack gap={1} width='100%'>
                  <Skeleton variant='rounded' width='100%' sx={{ color: 'red' }} />
                  <Skeleton variant='rounded' width='60%' />
                </Stack>
              )}
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => setIsProfileOpen(true)}>
              <AccountCircleIcon
                sx={{ fontSize: '24px', marginRight: '10px', color: 'kqn.darkGray' }}
              />
              {!isFetching ? 'Profile' : <Skeleton variant='rounded' width='60%' />}
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => setIsChangePasswordOpen(true)}>
              <LockIcon sx={{ fontSize: '24px', marginRight: '10px', color: 'kqn.darkGray' }} />
              {!isFetching ? 'Change Password' : <Skeleton variant='rounded' width='100%' />}
            </MenuItem>
            <Divider />
            <MenuItem
              data-testid='navbar-logout-btn'
              onClick={handleLogout}
              sx={{ color: 'kqn.copper' }}
            >
              LOG OUT
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
      <SidebarUserProfileModal
        user={pageableUser}
        isOpen={isProfileOpen}
        onClose={() => setIsProfileOpen(false)}
      />
      <ChangePasswordModal
        isOpen={isChangePasswordOpen}
        onClose={() => setIsChangePasswordOpen(false)}
      />
    </Stack>
  );
};

export default SidebarUserActions;
