import { Box, Divider, Typography } from '@mui/material';
import ModalPopup from '../modal-popup/modal-popup';
import { ResetPasswordForm } from '../reset-password-form';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const ChangePasswordModal: React.FC<Props> = ({ isOpen, onClose }) => {
  return (
    <ModalPopup isOpen={isOpen} onClose={onClose}>
      <Box
        border={0}
        sx={{
          width: '500px',
          bgcolor: 'background.paper',
          p: 3,
          gap: 2,
          boxShadow: 24,
          borderRadius: '8px',
        }}
      >
        <Typography variant='h6' sx={{ color: 'kqn.copper', pb: 2 }}>
          CHANGE PASSWORD
        </Typography>
        <Divider sx={{ borderColor: 'kqn.copper', mb: 1 }} />
        <ResetPasswordForm onClose={onClose} />
      </Box>
    </ModalPopup>
  );
};

export default ChangePasswordModal;
