import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button, CircularProgress, Stack } from '@mui/material';
import { useState } from 'react';
import { Optional } from '../../../../../../components';
import { useAuthentication } from '../../../../../../context';
import { RecipeBaseModel } from '../../../../../types';
import ExportRecipe from '../../../export-recipe/export-recipe';
import { DuplicateRecipeModal } from '../../../modals';
import { useHeaderConfig } from '../hooks/use-header-config';

type Props = {
  recipe?: RecipeBaseModel;
  hasUnsavedChanges: boolean;
  value: number;
  isLoading?: boolean;
  onSave?: () => void;
  onDiscard?: () => void;
};

const HeaderActions: React.FC<Props> = ({
  value,
  recipe,
  isLoading,
  onSave,
  hasUnsavedChanges,
  onDiscard,
}) => {
  const [openDuplicate, setOpenDuplicate] = useState(false);
  const { user: authUser } = useAuthentication();
  const isTester = authUser?.role === 'TESTER';
  const isEdit = !!recipe?.id;
  const { hasDuplicate, hasSave, hasExport } = useHeaderConfig(value);

  const handleDuplicate = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();

    setOpenDuplicate(true);
  };

  if (!recipe) return null;

  return (
    <Stack gap={1} direction='row' alignItems='center'>
      {isEdit && !isTester && (
        <>
          {recipe.id && hasExport && <ExportRecipe recipeId={recipe.id} />}
          {hasDuplicate && (
            <Button
              data-testid='duplicate-button'
              onClick={handleDuplicate}
              startIcon={<ContentCopyIcon />}
              sx={{
                bgcolor: 'kqn.white',
                color: 'kqn.copper',
                borderColor: 'kqn.lightCopper',
                ':hover': { bgcolor: 'kqn.gray', borderColor: 'kqn.copper' },
              }}
              variant='outlined'
            >
              DUPLICATE
            </Button>
          )}
        </>
      )}
      {hasSave && (
        <Button
          disabled={!hasUnsavedChanges}
          variant='contained'
          color='secondary'
          onClick={onDiscard}
          sx={{
            display: hasUnsavedChanges ? 'block' : 'none',
            width: 'fit-content',
            height: 'fit-content',
          }}
        >
          DISCARD
        </Button>
      )}
      {hasSave && (
        <Button
          variant='contained'
          color='primary'
          data-testid='save-button'
          disabled={!hasUnsavedChanges || isLoading}
          onClick={onSave}
          sx={{
            display: hasUnsavedChanges ? 'flex' : 'none',
            height: 'fit-content',
            color: 'kqn.white',
            '&:hover': { bgcolor: 'kqn.darkerGray' },
          }}
          startIcon={isLoading && <CircularProgress size={15} sx={{ color: 'kqn.copper' }} />}
        >
          SAVE
        </Button>
      )}
      <Optional condition={openDuplicate}>
        <DuplicateRecipeModal
          recipe={recipe}
          isOpen={openDuplicate}
          onClose={() => setOpenDuplicate(false)}
        />
      </Optional>
    </Stack>
  );
};

export default HeaderActions;
