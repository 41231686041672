import { Chip, Stack, Typography } from '@mui/material';
import { Title } from '../../../../components';
import { ErrorKeys } from '../../../../constants';
import { useErrorContext } from '../../../../context';
import { CategoryModel } from '../../../types';

type Props = {
  categories?: CategoryModel[];
  pickedCategories?: CategoryModel[];
  updateCategory?: (categories: CategoryModel) => void;
};

const CategoriesPicker: React.FC<Props> = ({ categories, pickedCategories, updateCategory }) => {
  const { error } = useErrorContext();

  return (
    <Stack direction='column' gap={1}>
      <Title title='Category' />
      <Stack direction='row' alignItems='center' flexWrap='wrap' gap={2}>
        {categories?.map((category) => {
          const isSelected = pickedCategories?.find(
            (pickedCategory) => pickedCategory.id === category.id,
          );
          return (
            <Chip
              data-testid='category-chip'
              key={category.id}
              onClick={() => updateCategory?.(category)}
              label={category.name}
              onDelete={isSelected ? () => updateCategory?.(category) : undefined}
              sx={{
                color: 'kqn.darkerGray',
                borderRadius: '30px',
                bgcolor: isSelected ? 'kqn.ultraLightCopper' : 'kqn.gray',
                '& .MuiChip-deleteIcon': {
                  color: 'kqn.copper',
                },
              }}
            />
          );
        })}
      </Stack>
      {error.size ? <Typography color='error'>{error.get(ErrorKeys.CATEGORIES)}</Typography> : null}
    </Stack>
  );
};

export default CategoriesPicker;
