import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { formatDate } from '../../../shared';
import { Event } from '../../types';
import { useCreateEventProperties } from '../hooks/use-create-event-properties';

type Props = {
  event: Event;
};

const EventItem: React.FC<Props> = ({ event }) => {
  const eventProp = useCreateEventProperties(event);

  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      sx={{ borderRadius: '2px', border: '1px solid #e0e0e0', py: 1, px: 2 }}
    >
      <Stack direction='row' gap={0.5} alignItems='center'>
        <ArrowForwardIosIcon className='icon' />
        <Stack
          alignItems='center'
          justifyContent='center'
          sx={{ bgcolor: 'kqn.gray', width: '30px', height: '30px', borderRadius: '50%', p: 1 }}
        >
          {eventProp?.icon}
        </Stack>
        <Typography sx={{ fontWeight: 'bold' }}>
          {event.user.firstName} {event.user.lastName}
        </Typography>
        <Typography>{eventProp.prefix}</Typography>
        <Typography>{event.topic}</Typography>
        <Link to={eventProp.url || ''}>
          <Typography sx={{ color: 'kqn.copper', fontWeight: 'bold' }}>{event.content}</Typography>
        </Link>
      </Stack>
      <Typography sx={{ fontWeight: 'bold' }}>{formatDate(event.createdAt)}</Typography>
    </Stack>
  );
};

export default EventItem;
