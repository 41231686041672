import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { RecipeGroupModel, RecipeIngredient } from '../../../../../../types';
import PeparationIngredientsPicker from './preparation-ingredients-picker';

type Props = {
  groups?: RecipeGroupModel[];
  ingredients?: string[];
  handleUpdateIngredients: (ingredients: string[]) => void;
};

const PreparationIngredients: React.FC<Props> = ({
  ingredients = [],
  groups,
  handleUpdateIngredients,
}) => {
  const [selectedGroup, setSelectedGroup] = useState<RecipeGroupModel | undefined>(
    groups?.find((grp) => grp.ingredients.find((i) => ingredients.includes(i.groupIngredientId!))),
  );
  const [selectedIngredients, setSelectedIngredients] = useState<RecipeIngredient[]>(
    selectedGroup?.ingredients.filter((ing) => ingredients.includes(ing.groupIngredientId!)) ?? [],
  );

  const handleUpdateGroup = useCallback(
    (e: SelectChangeEvent<string>) => {
      const newGroup = groups?.find((grp) => grp.id === e.target.value);
      if (!newGroup) {
        setSelectedGroup(undefined);
        handleUpdateIngredients([]);
        setSelectedIngredients([]);

        return;
      }

      setSelectedGroup(newGroup);
      handleUpdateIngredients([]);
      setSelectedIngredients([]);
    },
    [groups, handleUpdateIngredients],
  );

  const handleIngredientSelect = (ingredient: RecipeIngredient) => {
    const newIngredient = selectedGroup?.ingredients.find((ing) => ing.id === ingredient.id);

    if (!newIngredient) return;

    setSelectedIngredients([...selectedIngredients, newIngredient]);
    handleUpdateIngredients([...ingredients, newIngredient.groupIngredientId!]);
  };

  const handleRemoveIngredient = (ingredient: RecipeIngredient) => {
    const newIngredients = selectedIngredients.filter((ing) => ing.id !== ingredient.id);

    setSelectedIngredients(newIngredients);
    handleUpdateIngredients(newIngredients.map((ing) => ing.groupIngredientId!));
  };

  if (!groups) return null;

  return (
    <Stack direction='row' sx={{ width: '100%', mt: 1 }}>
      <FormControl sx={{ width: '20%', mr: 3 }} size='small'>
        <InputLabel
          htmlFor='group-ingredient-select'
          sx={{
            color: 'kqn.darkerGray',
            borderColor: 'kqn.darkerGray',
            '&.Mui-focused': {
              color: 'kqn.copper',
            },
          }}
        >
          INGREDIENT GROUP
        </InputLabel>
        <Select
          defaultValue=''
          multiline={false}
          value={selectedGroup?.id ?? ''}
          onChange={handleUpdateGroup}
          id='group-ingredient-select'
          data-testid='group-ingredient-select'
          input={<OutlinedInput id='group-ingredient-select' label='INGREDIENT GROUP' />}
          sx={{
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              color: 'kqn.copper',
              borderColor: 'kqn.copper',
            },
          }}
        >
          {[{ name: 'None', id: '' }, ...groups]?.map((grp) => (
            <MenuItem
              key={grp.id}
              value={grp.id}
              data-testid='step-ingredient-item'
              sx={{
                '&.Mui-selected': {
                  bgcolor: 'kqn.ultraLightCopper',
                },

                '&.Mui-selected:hover': {
                  bgcolor: 'kqn.ultraLightCopper',
                },
              }}
            >
              {grp.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Stack direction='row' gap={1}>
        <PeparationIngredientsPicker data={selectedIngredients} onDelete={handleRemoveIngredient} />
        <PeparationIngredientsPicker
          data={selectedGroup?.ingredients.filter((ig) => !selectedIngredients.includes(ig)) || []}
          onClick={handleIngredientSelect}
        />
      </Stack>
    </Stack>
  );
};

export default PreparationIngredients;
