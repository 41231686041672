import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useRecipesFilter, useSearch } from '../../context';
import { usePathname } from '../../shared';
import { ListItemType } from './sidebar.types';

type Props = {
  data: ListItemType[];
  open: boolean;
};

const MenuListItems: React.FC<Props> = ({ data, open }) => {
  const { paths } = usePathname();
  const { resetSearchQuery, query } = useSearch();
  const { resetFilter, query: recipesQuery } = useRecipesFilter();

  const handleOnClick = () => {
    if (query !== '') resetSearchQuery();
    if (recipesQuery !== '') resetFilter();
  };
  return (
    <List>
      {data.map(({ text, icon, url, testId, path }) => {
        const isActive = path.includes(paths[1]);

        return (
          <Link to={url} key={text} onClick={handleOnClick}>
            <ListItem
              disablePadding
              sx={{
                display: 'block',
                color: isActive ? 'kqn.copper' : 'kqn.darkerGray',
                px: 1,
                my: 1,
                borderRadius: '8px',
              }}
            >
              <ListItemButton
                data-testid={testId}
                sx={{
                  minHeight: 42,
                  justifyContent: open ? 'initial' : 'center',
                  alignItems: 'center',
                  px: 0,
                  py: 0,
                  borderRadius: '8px',
                  '&.Mui-selected': { backgroundColor: 'kqn.ultraLightCopper' },
                  '&.MuiButtonBase-root:hover': { bgcolor: 'kqn.gray' },
                }}
                selected={isActive}
              >
                <Stack direction='row' gap={1.5} alignItems='center' justifyContent='space-between'>
                  <Box
                    component='span'
                    sx={{
                      height: '22px',
                      width: '4px',
                      borderRadius: '0 8px 8px 0',
                      bgcolor: 'kqn.copper',
                      visibility: isActive ? 'visible' : 'hidden',
                    }}
                  />
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 2 : 'auto',
                      justifyContent: 'center',
                      color: isActive ? 'kqn.copper' : 'kqn.darkerGray',
                    }}
                  >
                    {icon}
                  </ListItemIcon>
                </Stack>
                <ListItemText
                  primary={text}
                  primaryTypographyProps={{ fontWeight: 600 }}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </Link>
        );
      })}
    </List>
  );
};

export default MenuListItems;
