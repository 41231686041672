import {
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Theme,
  useTheme,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useErrorContext, useRecipesFilter } from '../../../../context';
import { queries } from '../../../queries';
import DurationFilter from './duration-filter';
import StateFilter from './state-filter';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      borderRadius: '8px',
    },
  },
};

function getStyles(value: string, values: readonly string[], theme: Theme) {
  return {
    fontWeight:
      values.indexOf(value) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const RecipesFilter: React.FC = () => {
  const theme = useTheme();
  const { setValue, data, setFilters, filters: availableFilters } = useRecipesFilter();
  const { error } = useErrorContext();
  const { complexity, category, cookingTime, mealType, state } = data;

  const { data: filters } = useQuery({
    ...queries.filters.all(),
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  });

  useEffect(() => {
    if (!filters) return;

    setFilters(filters);
  }, [filters, setFilters]);

  const { categories, complexities, mealTypes } = availableFilters;

  const components = [
    {
      name: 'Complexity',
      value: complexity,
      onchange: setValue,
      selectName: 'complexity',
      outlineLabel: 'Complexity',
      options: complexities,
      errorKey: 'complexities-complexities',
    },
    {
      name: 'Category',
      value: category,
      onchange: setValue,
      selectName: 'category',
      outlineLabel: 'Category',
      options: categories,
      errorKey: 'categories-categories',
    },
    {
      name: 'Meal Type',
      value: mealType,
      onchange: setValue,
      selectName: 'mealType',
      outlineLabel: 'Meal Type',
      options: mealTypes,
      errorKey: 'mealTypes-mealTypes',
    },
  ];

  return (
    <Stack direction='column' gap={2}>
      <Stack direction='row' justifyContent='space-between' alignItems='baseline' gap={2}>
        {components.map(({ name, onchange, options, selectName, value, errorKey }) => (
          <Stack gap={1} width='100%'>
            <InputLabel sx={{ color: 'kqn.darkerGray' }}>{name}</InputLabel>
            <FormControl
              sx={{ width: '100%' }}
              key={name}
              error={!!error.get(`${errorKey}-${errorKey}`)}
            >
              <Select
                defaultValue={[]}
                multiple
                value={value}
                onChange={onchange}
                name={selectName}
                sx={{
                  color: 'kqn.darkerGray',
                  borderRadius: '8px',
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    color: 'kqn.lightGray',
                    borderColor: 'kqn.copper',
                    py: '0px !important',
                  },
                  '& .MuiOutlinedInput-input': {
                    py: '8px !important',
                  },
                }}
                renderValue={(selected) => (
                  <Stack direction='row' flexWrap='wrap' gap={0.5}>
                    {selected.map((val) => (
                      <Chip
                        key={val}
                        label={val}
                        sx={{ bgcolor: 'kqn.copper', color: 'kqn.white', height: '24px' }}
                      />
                    ))}
                  </Stack>
                )}
                MenuProps={{
                  ...MenuProps,
                  sx: {
                    '&& .Mui-selected': {
                      backgroundColor: 'kqn.ultraLightCopper',
                    },
                  },
                }}
              >
                {options.map(({ id, name: filterName }) => (
                  <MenuItem
                    key={id}
                    value={filterName}
                    style={getStyles(filterName, complexity, theme)}
                    sx={{ py: ' 8px !important' }}
                  >
                    {filterName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        ))}
        <DurationFilter cookingTime={cookingTime} setValue={setValue} />
        <StateFilter state={state} setValue={setValue} />
      </Stack>
    </Stack>
  );
};

export default RecipesFilter;
