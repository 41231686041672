import { TableBody, TableRow } from '@mui/material';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { KQNCheckbox, TableCellStyled } from '../../../../components';
import { getComparator, Order, stableSort } from '../../../../shared';
import { PaginatedResult } from '../../../../types';
import { PageableUnit, UnitHeadCells } from '../../../types';

type Props = {
  data: PaginatedResult<PageableUnit>;
  selected: string[];
  perPage: number;
  order: Order;
  orderBy: keyof UnitHeadCells;
  handleOnSelect: (event: React.MouseEvent<unknown, MouseEvent>, name: string) => void;
};

const UnitTableBody: React.FC<Props> = ({
  data,
  perPage,
  order,
  orderBy,
  handleOnSelect,
  selected,
}) => {
  const navigate = useNavigate();
  const { data: units } = data;
  const isSelected = (id: string) => selected.indexOf(id) !== -1;
  const records = orderBy !== 'name' ? stableSort(units, getComparator(order, orderBy)) : units;

  const rows = useMemo(() => records.slice(0, perPage), [perPage, records]);

  const handleOnClick = (id: string) => {
    navigate(`/recipes/units/${id}`);
  };

  return (
    <TableBody>
      {rows.map((row, index) => {
        const isItemSelected = isSelected(row.id);
        const labelId = `enhanced-table-checkbox-${index}`;

        return (
          <TableRow
            hover
            role='checkbox'
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.id}
            selected={isItemSelected}
            sx={{
              cursor: 'pointer',
              '&.Mui-selected': {
                bgcolor: 'kqn.ultraLightCopper',
              },

              '&.Mui-selected:hover': {
                bgcolor: 'kqn.ultraLightCopper',
              },
            }}
          >
            <TableCellStyled padding='checkbox'>
              <KQNCheckbox
                onClick={(event) => handleOnSelect(event, row.id)}
                checked={isItemSelected}
                labelId={labelId}
              />
            </TableCellStyled>
            <TableCellStyled
              component='th'
              id={labelId}
              scope='row'
              onClick={() => handleOnClick(row.id)}
            >
              {row.name}
            </TableCellStyled>
            <TableCellStyled onClick={() => handleOnClick(row.id)}>{row.nameDe}</TableCellStyled>
            <TableCellStyled onClick={() => handleOnClick(row.id)}>
              {row.namePlural}
            </TableCellStyled>
            <TableCellStyled onClick={() => handleOnClick(row.id)}>
              {row.nameDePlural}
            </TableCellStyled>
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default UnitTableBody;
