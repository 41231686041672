import { Box, Divider, Stack, Typography } from '@mui/material';
import { ModalPopup } from '../../../../../../../../../../components';
import { Meal } from '../../../../../../../../../types';
import { CreateMealForm } from './forms';

interface Props {
  recipeId: string;
  isOpen: boolean;
  onClose: () => void;
  meal?: Meal;
}

const MealComposer: React.FC<Props> = ({ recipeId, isOpen, onClose, meal }) => {
  return (
    <ModalPopup isOpen={isOpen} onClose={onClose}>
      <Box
        border={0}
        sx={{
          minWidth: '600px',
          bgcolor: 'background.paper',
          p: 3,
          boxShadow: 24,
          borderRadius: '8px',
        }}
      >
        <Stack gap={2} pb={2} direction='row' alignItems='center' justifyContent='flex-start'>
          <Typography variant='h6'>Add Meal</Typography>
        </Stack>
        <Divider variant='middle' sx={{ borderColor: 'kqn.lightCopper' }} />
        <CreateMealForm recipeId={recipeId} onClose={onClose} mealTypeStep={meal} />
      </Box>
    </ModalPopup>
  );
};
export default MealComposer;
