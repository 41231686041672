import { Chip, Stack, Typography } from '@mui/material';
import { Title } from '../../../../components';
import { ErrorKeys } from '../../../../constants';
import { useErrorContext } from '../../../../context';
import { RecipeComplexityModel } from '../../../types';

type Props = {
  complexities?: RecipeComplexityModel[];
  pickedComplexity?: RecipeComplexityModel;
  updateComplexity?: (complexity: RecipeComplexityModel) => void;
};

const sortOrder: { [key: string]: number } = {
  Low: 1,
  Medium: 2,
  High: 3,
};

const ComplexityPicker: React.FC<Props> = ({
  complexities,
  pickedComplexity,
  updateComplexity,
}) => {
  const { error } = useErrorContext();

  return (
    <Stack direction='column' gap={1}>
      <Title title='Complexity' />
      <Stack direction='row' alignItems='center' flexWrap='wrap' gap={2}>
        {complexities
          ?.sort((a, b) => sortOrder[a.name] - sortOrder[b.name])
          .map((complexity) => {
            const isSelected = pickedComplexity?.id === complexity.id;
            return (
              <Chip
                key={complexity.id}
                data-testid='complexity-chip'
                label={complexity.name}
                onClick={() => updateComplexity?.(complexity)}
                sx={{
                  textTransform: 'uppercase',
                  color: isSelected ? 'kqn.white' : 'kqn.darkerGray',
                  borderRadius: '30px',
                  bgcolor: isSelected ? 'kqn.copper' : 'kqn.gray',
                }}
              />
            );
          })}
      </Stack>
      {error.size ? <Typography color='error'>{error.get(ErrorKeys.COMPLEXITY)}</Typography> : null}
    </Stack>
  );
};

export default ComplexityPicker;
