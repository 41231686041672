/* eslint-disable @typescript-eslint/naming-convention */
import { Stack } from '@mui/material';
import { DiscardChangesModal } from '../../../../../components';
import { useErrorContext } from '../../../../../context';
import { RecipeBaseModel } from '../../../../types';
import { useRecipeComposerActions, useRecipeEditor, useRecipeHeaderActions } from '../../../hooks';
import { HeaderActions } from './header-actions';
import TabItems from './tab-items';

interface Props {
  recipe?: RecipeBaseModel;
  value: number;
  onChange: (val: number) => void;
  isDisabled?: boolean;
}

const HeaderTabs: React.FC<Props> = ({ value, onChange, isDisabled, recipe }) => {
  const { resetError } = useErrorContext();
  const { isOpen, handleClose, handleOpen, onDiscard } = useRecipeComposerActions();
  const { hasUnsavedChanges } = useRecipeEditor();
  const handleCallback = useRecipeHeaderActions(value);

  const handleOnChange = (val: number) => {
    if (isDisabled || handleCallback?.isLoading) return;
    if (hasUnsavedChanges) {
      handleOpen();
      return;
    }

    resetError();
    onChange(val);
  };

  return (
    <Stack
      justifyContent='space-between'
      direction='row'
      width='100%'
      px={4}
      sx={{ backgroundColor: 'kqn.copperSilver' }}
    >
      <TabItems onChange={handleOnChange} value={value} />
      <HeaderActions
        value={value}
        recipe={recipe}
        isLoading={handleCallback?.isLoading}
        onSave={handleCallback?.handler}
        onDiscard={onDiscard}
        hasUnsavedChanges={hasUnsavedChanges}
      />
      <DiscardChangesModal
        isOpen={isOpen}
        onSave={handleCallback?.handler}
        onClose={handleClose}
        onDiscard={onDiscard}
      />
    </Stack>
  );
};

export default HeaderTabs;
