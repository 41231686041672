import { Box, Divider, Stack, SxProps, Typography } from '@mui/material';
import { ModalPopup } from '../modal-popup';

type Props = {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  icon?: React.ReactNode;
  sx?: SxProps;
};

const ModalComposer: React.FC<Props> = ({ title, isOpen, onClose, children, icon, sx }) => {
  return (
    <ModalPopup isOpen={isOpen} onClose={onClose}>
      <Box
        border={0}
        sx={{ minWidth: '600px', bgcolor: 'background.paper', p: 3, boxShadow: 24, ...sx }}
      >
        <Stack
          gap={2}
          sx={{ color: 'kqn.copper' }}
          pb={2}
          direction='row'
          alignItems='center'
          justifyContent='flex-start'
        >
          {icon}
          <Typography variant='h6'>{title}</Typography>
        </Stack>
        <Divider sx={{ borderColor: 'kqn.copper' }} />
        <Stack gap={2}>{children}</Stack>
      </Box>
    </ModalPopup>
  );
};

export default ModalComposer;
