import { Box, Stack, Tab, Tabs } from '@mui/material';
import { useStepCompleted } from './hooks/use-step-completed';
import { tabs } from './tabs-config';

interface Props {
  value: number;
  onChange: (val: number) => void;
}

const TabItems: React.FC<Props> = ({ value, onChange }) => {
  const hasCompleted = useStepCompleted();

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    onChange(newValue);
  };

  return (
    <Stack
      direction='row'
      alignItems='center'
      justifyContent='space-between'
      gap={2}
      flexGrow={1}
      width='100%'
      data-testid='tab-items'
    >
      <Tabs
        value={value}
        onChange={handleChange}
        sx={{
          gap: 1,
          '& .MuiTabs-indicator': { bgcolor: 'kqn.copper' },
        }}
      >
        {tabs.map(({ icon, label, testid }) => {
          const isCompleted = hasCompleted(label);
          return (
            <Tab
              key={label}
              data-testid={testid}
              label={
                <div
                  style={{
                    display: 'flex',
                    position: 'relative',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    gap: '0.5rem',
                  }}
                >
                  {isCompleted && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: -5,
                        right: -10,
                        p: 0.5,
                        borderRadius: '50%',
                        bgcolor: 'kqn.ultraLightGreen',
                        boxShadow: '2px 2px 2px 0px rgba(0, 0, 0, 0.25) inset',
                      }}
                    />
                  )}
                  {icon}
                  <Box component='span'>{label}</Box>
                </div>
              }
              sx={{
                py: 0,
                '&.Mui-selected': { color: 'kqn.copper' },
              }}
            />
          );
        })}
      </Tabs>
    </Stack>
  );
};

export default TabItems;
